
@mixin drawSecondaryNav($mediaWidth, $componentWidth) {
    nav.sticky-left-sidebar {
        max-width: $componentWidth;
/*        position: sticky;*/

        @media(min-width:$mediaWidth) {
            display: block;
        }
    }
}

.au-campaign-page {
    @include drawSecondaryNav(992px, 300px)
}

header#sticky-site-header.sticky {
    @media (max-width: 576px) {
        box-shadow: none;
    }
}
nav.sticky-left-sidebar {
    display: none;
    /*    position: fixed;*/
    padding: 20px 10px 0 10px;
    /*    top: 108px;*/

    &.sticky-fixed {
        position: absolute;
        top: unset;
    }

    .sidebar-nav {
        padding: 0 20px 0 10px;
        /*        border-right: 2px solid #97979738;*/
        min-width: 0;

        &__item {
            list-style: none;
            cursor: pointer;
            font-size: 14px;
            color: #323232;
            letter-spacing: 0.5px;
            line-height: 1.2;
            margin-bottom: 0.5rem;
            font-weight: 600;
            border-bottom-style: none;
            border-bottom-width: 0px;
            border-bottom-color: #fff;
            margin-right: 15px;

            &:hover {
                font-weight: bold;
                margin-right: 8px;
            }

            &.active {
                padding-bottom: 1px;
                font-weight: bold;
                border-bottom: 2px solid #0ab2bf;
                margin-bottom: calc(0.5rem - 3px);
                margin-right: 8px;
            }
        }
    }
}

.secondary-nav-wrp .sticky-header {
    display: none;
}

.secondary-nav-wrp.fixed-nav {

    .sticky-header {
        position: fixed;
        /*        display: block;*/
        width: 100%;
        background-color: white;
        z-index: 2;
        left: 0;

        @media screen and (max-height: 420px) and (orientation:landscape) {
            z-index: 999;
        }

        .inner-container {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #979797;
            color: #323232;
            min-height: 50px;
            align-items: center;

            @media (max-width: 576px) {
                display: none;
            }

            .sticky-links {
                display: none;

                .sticky-link {
                    letter-spacing: 1px;
                    line-height: 17px;
                    text-transform: none;

                    &:hover, &:focus {
                        text-decoration: none;
                        font-weight: bold;
                        letter-spacing: 0.85px;
                        color: #001e6d;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid black;
                    }

                    &:last-child {
                        padding-right: 0 !important;
                    }
                }
            }

            .md-secondary-nav {
                display: flex;

                .dropdown__button-simple-toggle {
                    height: 35px;
                    padding: 0 0.5rem;
                    border-radius: 0;
                    border: 0;
                    background-color: #f0f3f6;
                    width: auto;

                    .dropdown__icon {
                        width: 18px;
                        position: static;
                    }
                }

                .dropdown-menu {
                    border-top: 1px solid #939598;

                    @media screen and (max-height: 420px) and (orientation:landscape) {
                        max-height: 220px;
                        overflow: scroll;
                    }

                    .dropdown-item {
                        cursor: pointer;
                    }
                }
                //to solve the s20 issue for the secondary nav
                @media(max-height:365px) and (orientation:landscape) {
                    .dropdown-menu {
                        max-height: 100px;
                        overflow: scroll;
                    }
                }
            }
        }

        .inner-container-mobile {

            .mobile-secondary-nav {
                margin-top: 7px;

                button.dropdown__button {
                    max-height: 50px;
                    border: 1px solid #535D66;
                    border-radius: 5px;
                    overflow: hidden;
                    line-height: 1.75;

                    span.dropdown-label {
                        vertical-align: top;
                    }
                }

                svg.dropdown__icon {
                    max-width: 18px;
                }
            }

            @media (min-width: 576px) {
                display: none;
            }
        }
    }
}
.secondary-nav-wrp:not(.fixed-nav) {
    nav.sticky-left-sidebar .sidebar-nav__item.active {
        // border-bottom: unset!important;
        border-bottom: 2px solid #0ab2bf;
    }
}

a.secondary-nav-link {
    background-color: unset;
    color: unset;
    text-decoration: unset;
}