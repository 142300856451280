.component-loading {
    text-align:center;
    padding-top: 200px;
    padding-bottom: 200px;
    position: relative;
    &:after {
        content: " ";
        background-image: url(/globalassets/home/us/common/ve-loading.gif);
        position: absolute;
        height: 150px;
        width: 150px;
        top: calc(50% - 75px);
        bottom: calc(50% - 75px);
        left: calc(50% - 75px);
        right: calc(50% - 75px);
    }
}